
import { UnionItemDefinition } from "@/types/responses"
import { RouteTypes } from "@/types/enums"

import Vue from "vue"
export default Vue.extend({
	name: "Home",
	methods: {
		bgImage(bgImage: string): void {
			return require(`@/assets/bg/${bgImage}`)
		},
		firstPermittedRoute(item: UnionItemDefinition): void {
			let path = "/"
			if (item.subItems.length > 0) {
				const itm = item.subItems.find((subItem) => {
					return subItem.isPermitted
				})
				if (itm) {
					path = itm.route
				}
			} else {
				path = item.route
			}
			this.$router.push({ path: path })
		},
	},
	computed: {
		modules(): Array<UnionItemDefinition> {
			const modules: UnionItemDefinition[] | null = this.$vStore.state!.nav!.modules!.items;
			const newModules: UnionItemDefinition[] = []
			if(modules){
				for(let i = 0; modules.length > i; i++){
					if(modules[i].route != RouteTypes.None) newModules.push(modules[i])
				}
				return newModules;
			}
			return []
		},
		hasContext(): boolean {
			const hasCtx = this.$vStore.state.context.selectedCustomer !== null

			const shouldShow = this.$route.path !== "/context" && this.$route.path !== "/profile"

			return hasCtx && shouldShow
		},
	}
})
